<template>
  <v-app style="background: #fafafa">
    <v-container fluid fill-height>
      <v-row justify="center">
        <v-alert
          dense
          v-if="showSuccess"
          class="login-alert"
          outlined
          text
          type="success"
          >Your password has been changed successfully
        </v-alert>

        <v-alert
          dense
          v-if="showError"
          class="login-alert"
          outlined
          text
          type="error"
          >{{ errorMsg }}
        </v-alert>

        <v-col cols="12">
          <v-row justify="center"> </v-row>
          <v-card
            elevation="0"
            class="login-card"
            v-bind:style="
              !isLinkValid || showSuccess ? 'height: 100%;' : 'height:500px'
            "
          >
            <v-row justify="center" class="py-8"
              ><img
                class="justify-center"
                :src="require('@/assets/images/privlogo.png')"
                alt="logo"
            /></v-row>
            <v-row justify="center"><v-col cols="11"> </v-col></v-row>
            <v-form ref="form" v-if="isLinkValid">
              <v-card-text v-if="!showSuccess">
                <v-text-field
                  v-if="!showSuccess"
                  outlined
                  dense
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="email"
                  v-model="email"
                  label="Email Address"
                  disabled
                ></v-text-field>

                <v-text-field
                  v-if="!showSuccess"
                  outlined
                  dense
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="password"
                  v-model="password"
                  label="Password"
                ></v-text-field>
                <p
                  v-if="!$v.password.required"
                  id="error"
                  class="text-error-message"
                >
                  Password is required
                </p>
                <p
                  v-if="password && !$v.password.maxLength"
                  id="error"
                  class="text-error-message"
                >
                  Password must be maximum 16 characters
                </p>
                <p
                  v-if="password && !$v.password.valid"
                  id="error"
                  class="text-error-message"
                >
                  Your password must be 8-16 characters, and include at least
                  one lowercase letter, one uppercase letter, and a number and a
                  special character
                </p>
                <p
                  v-if="password && $v.password.valid && !$v.password.minLength"
                  id="error"
                  class="text-error-message"
                >
                  Password must be minimum 8 characters
                </p>

                <v-text-field
                  outlined
                  dense
                  background-color="#FAFAFA"
                  class="rounded-0"
                  ref="repeatPassword"
                  v-model="repeatPassword"
                  label="Confirm Password"
                ></v-text-field>

                <p
                  v-if="!$v.repeatPassword.sameAsPassword"
                  id="error"
                  class="text-error-message"
                >
                  Passwords must be identical.
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  color="#18739d"
                  text
                  :ripple="false"
                  plain
                  style="
                    text-transform: unset !important;
                    opacity: 1 !important;
                  "
                  :to="login_route"
                  ><v-icon>mdi-arrow-left</v-icon>Back to Login</v-btn
                >
                <v-spacer></v-spacer>

                <v-btn
                  style="font-size: 14px !important; width: 150px !important"
                  :loading="isLoading"
                  elevation="0"
                  v-if="!showSuccess"
                  :ripple="false"
                  :disabled="isDisabled"
                  class="login-button white--text"
                  @click="submit"
                >
                  Change Password
                </v-btn>
              </v-card-actions>
            </v-form>

            <v-spacer></v-spacer>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <FooterItem />
  </v-app>
</template>

<script>
import jwt from "jsonwebtoken";
import axios from "axios";
import FooterItem from "@/components/FooterItem.vue";

import {
  required,
  sameAs,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

const SECRET = "2msglh0vaaqogn96fig5d5ohfk6v0932dremjhgr5ad5gmg0d7t";

export default {
  components: { FooterItem },
  setup() {},
  created() {
    try {
      const payload = jwt.verify(this.token, SECRET);
      this.email = payload.email;
      this.isLinkValid = true;
    } catch (err) {
      this.isLinkValid = false;
      this.showError = true;
      this.errorMsg = "Link is invalid or expired";
    }
  },
  methods: {
    async submit() {
      this.isLoading = true;
      if (
        this.password != "" &&
        this.repeatPassword != "" &&
        this.password != this.repeatPassword
      ) {
        return false;
      } else {
        const payload = {
          execution_mode: "change_password",
          email: this.email,
          token: this.token,
          password: this.password,
        };
        await axios
          .post(
            "https://vu3xcwlw3b.execute-api.eu-west-1.amazonaws.com/default/age-dashboard-change-password",
            payload,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((resp) => {
            this.isLoading = false;
            if (resp.data.result == "DENY") {
              this.showError = true;
              this.errorMsg = resp.data.reason;
            } else {
              this.showSuccess = true;
              this.showError = false;
            }
          })
          .catch((err) => {
            this.isLoading = false;
            this.showError = true;
            this.errorMsg = "An unknown exception occurred";
            console.log(err);
          });
      }
    },
  },
  computed: {
    isDisabled() {
      return this.$v.$invalid;
    },
  },
  validations: {
    password: {
      required,
      valid: function (value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return (
          containsUppercase &&
          containsLowercase &&
          containsNumber &&
          containsSpecial
        );
      },
      minLength: minLength(8),
      maxLength: maxLength(16),
    },
    repeatPassword: { required, sameAsPassword: sameAs("password") },
  },
  data() {
    return {
      email: this.$route.params.email,
      login_route: "/login",
      token: this.$route.params.token,
      isLinkValid: false,
      password: "",
      repeatPassword: "",
      showSuccess: false,
      errorMsg: "",
      showError: false,
      isLoading: false,
      links: [
        {
          title: "Privacy Policy",
          link: "/static/docs/privacy_policy.pdf",
        },
        {
          title: "Terms and Conditions",
          link: "/static/docs/terms_and_conditions.pdf",
        },
        {
          title: "Contact Us",
          link: "mailto:dev_channel_contact@privately.eu?subject=General Inquiry about Privately Developer Hub",
          mailto: true,
        },
      ],
    };
  },
};
</script>
